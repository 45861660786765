<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white grid grid-cols-12 " :style="`height:` +  mainHeight + `px; !important`">
        <div class=" mx-2 shadow-md relative" :class="isFilterOpen ? 'box col-span-10' : 'box col-span-12'">
          <div class="bg-white cust_card p-2 heading-3 text-text1 justify-between flex">
            <p>PerimeterTechnologies.com Sales by Product Category</p>
            <div class="flex">
            </div>
            <div class="top-0 absolute" :class="isFilterOpen ? '-right-4' : 'transition-all delay-75 -right-2'" >
              <button id="toggle" class="bg-gray-600 hover:bg-gray-700 transition delay-75 w-11 h-11 rounded-l-full " @click="openCloseFilter()">
                <span v-if="isFilterOpen"><i class="fas fa-caret-right h-6 w-6 text-white mt-1"></i></span>
                <p v-if="!isFilterOpen"><i class="fas fa-caret-left h-6 w-6 text-white mt-1"></i></p>
              </button>
            </div>
          </div>
          <div style="overflow-y: auto; white-space: nowrap;overflow-x: hidden;" class="table_containder" v-if="ExtFListingObj.length > 0 && firstLoading">
            <div :style="`max-height:` +  cardHight + `px; !important`" class="pt-2">
              <div id="chart">
                <apexchart type="pie" width="750" :options="chartOptions" :series="series"></apexchart>
              </div>
            </div>
          </div>
          <div class="layout m-2" v-if="ExtFListingObj.length === 0 && firstLoading">
            <div class="flex p-3 text-sm text-primary2 bg-white border-blue-100 border-2 rounded-lg  items-center" role="alert">
              <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
              <span class="sr-only">Info</span>
              <div>
                <p class=" font-medium heading-4">No record found.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="box col-span-2 overflow-y-auto mx-2 shadow-md transition-transform delay-300 ease-in-out transition-2" :style="!isFilterOpen ? 'display: none' : ''">
          <GlobalFilter :callFrom="'PerimeterTechnologies.com'" :callParent="'accounting'"  @closeFilter="closeFilter" :isFilterOpen="isFilterOpen"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import API from '@/api/App.js'
import GlobalFilter from '@/View/Admin/Components/filterWithoutSelection.vue'
// import Pagination from '@/View/components/pagination.vue'
export default {
  components: {
    GlobalFilter,
  },
  data () {
    return {
      series: [],
      chartOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        dataLabels: {
          enabled: false
        },
        labels: [],
        labels2: [],
        tooltip: {
          custom: function({series, seriesIndex, dataPointIndex, w}) {
            console.log('series', series)
            console.log('seriesIndex', seriesIndex)
            console.log('dataPointIndex', dataPointIndex)
            console.log('w', w)
            return '<ul style="padding:4px;">' +
            '<li><b>Category</b>: ' + w.config.labels[seriesIndex]+ '</li>' +
            '<li><b>Total Revenue</b>: $' + w.config.labels2[seriesIndex].toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '</li>' +
            '<li><b>Total Orders</b>: $' + w.config.series[seriesIndex] + '</li>' +
            '</ul>';
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      isFilterOpen: true,
      ExtFListingObj: [],
      filterObject: {
        startDate: '',
        endDate: '',
        minDate: '',
        maxDate: '',
      },
      selectedValue: 'last30Days',
      cardHight: 0,
      mainHeight: 0,
      defaultStartDate: '',
      defaultEndDate: '',
      firstLoading: false,
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    this.cardHight = window.innerHeight - 171
    this.mainHeight = window.innerHeight - 90
    console.log('this.cardHight', this.cardHight, this.mainHeight)
    this.defaultEndDate = new Date()
    let currentDate = new Date()
    this.defaultStartDate = new Date(currentDate.setDate(currentDate.getDate() - 29))
    this.filterObject.startDate = this.defaultStartDate
    this.filterObject.endDate = this.defaultEndDate
  },
  watch: {
    filterObject: {
      handler () {
        console.log('filterObject', this.filterObject)
      },
      deep: true
    },
  },
  methods: {
    closeFilter (data) {
      console.log('data', data)
      this.filterObject.startDate = data.startDate
      this.filterObject.endDate = data.endDate
      this.getListing()
    },
    openCloseFilter () {
      if (this.isFilterOpen) {
        this.isFilterOpen = false
      } else {
        this.isFilterOpen = true
      }
      console.log('openCloseFilter')
    },
    getListing () {
      this.series = []
      this.chartOptions.labels = []
      this.chartOptions.labels2 = []
      this.firstLoading = false
      console.log('this.filterObject.startDate 123 123', this.filterObject.startDate)
      console.log('this.filterObject.endDate 123 123', this.filterObject.endDate)
      let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
      let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait, it takes some time ...'})
      API.PerimTexhnoSalesProductPieChart(
        utcSTART,
        utcEND,
        response => {
          this.firstLoading = true
          this.ExtFListingObj = response.Data === null ? [] : response.Data
          for (let I = 0; I < this.ExtFListingObj.length; I++) {
            this.series.push(this.ExtFListingObj[I].totalOrders)
            this.chartOptions.labels.push(this.ExtFListingObj[I].name)
            this.chartOptions.labels2.push(this.ExtFListingObj[I].totalRevenue)
          }
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
  }
}
</script>
<style scoped>

.xsmall_col {
  /* min-width: 54px !important; */
  /* width: 54px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.dates_col {
  /* min-width: 120px !important; */
  /* width: 120px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.total_col {
  /* min-width: 82px !important; */
  /* width: 82px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.vsmall_col {
  /* min-width: 94px !important; */
  /* width: 94px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.avgSell_col {
  /* min-width: 133px !important; */
  /* width: 133px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.avgSell2_col {
  /* min-width: 138px !important; */
  /* width: 138px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.mid_col {
  /* min-width: 180px !important; */
  /* width: 180px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small_col {
  /* min-width: 84px !important; */
  /* width: 84px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.large_col {
  /* min-width: 260px !important; */
  /* width: 260px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.desc_col {
  /* min-width: 240px !important; */
  /* width: 240px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

</style>
