<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white">
        <div>
          <SalesChart></SalesChart>
        </div>
        <div class="mt-8">
          <SalesByProductPieChart></SalesByProductPieChart>
        </div>
        <div class="mt-8">
          <GeographyChart></GeographyChart>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import GeographyChart from './geographyChart.vue';
import SalesByProductPieChart from './salesByProductPieChart.vue';
import SalesChart from './salesChart.vue'
export default {
  components: {
    SalesChart,
    SalesByProductPieChart,
    GeographyChart
},
  data () {
    return {
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    document.title = 'Consumer Sales ◾ PerTechnologies'
  },
  watch: {
  },
  methods: {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
</style>
